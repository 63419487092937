import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import logo from '../img/follow-tuscany-logo.svg';
import facebook from '../img/social/facebook.svg';
import instagram from '../img/social/instagram.svg';

const StyledFooter = styled.footer`
    padding: 3rem 0rem 0rem;
    padding-bottom: 2rem;
`;

const MenuList = styled.ul`
    margin-left: 2rem;
    margin-top: 1rem;
    list-style: none;
`;

const CopyrightInfo = styled.span`
    color: #4a4a4a;
`;

const Footer = () => (
    <StyledFooter className="footer has-text-white-ter">
        <div className="content has-text-centered">
            <Link to="/" title="Logo">
                <img
                    src={logo}
                    alt="Follow Tuscany"
                    style={{ width: '14em', height: '10em' }}
                />
            </Link>
        </div>
        <section className="section">
            <div className="container">
                <div className="content content has-text-centered has-text-white-ter">
                    <div className="columns">
                        <div className="column is-3">
                            <MenuList>
                                <li>
                                    <Link className="navbar-item" to="/about">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="navbar-item"
                                        to="/your-home-away"
                                    >
                                        Your Home Away
                                    </Link>
                                </li>
                                <li>
                                    <Link className="navbar-item" to="/courses">
                                        Our Courses
                                    </Link>
                                </li>
                            </MenuList>
                        </div>
                        <div className="column is-3">
                            <MenuList>
                                <li>
                                    <Link
                                        className="navbar-item"
                                        to="/slow-living"
                                    >
                                        Slow Living
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="navbar-item"
                                        to="/contact-us"
                                    >
                                        Contact
                                    </Link>
                                </li>
                            </MenuList>
                        </div>
                        <div className="column is-3">
                            <MenuList>
                                <li>
                                    <Link
                                        className="navbar-item"
                                        to="/privacy-policy"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="navbar-item"
                                        to="/terms-and-conditions"
                                    >
                                        Terms and Conditions
                                    </Link>
                                </li>
                            </MenuList>
                        </div>
                        <div className="column is-3 social">
                            <a
                                title="facebook"
                                href="https://facebook.com/followtuscany/"
                            >
                                <img
                                    src={facebook}
                                    alt="Facebook"
                                    style={{ width: '1em', height: '1em' }}
                                />
                            </a>
                            <a
                                title="instagram"
                                href="https://instagram.com/followtuscany_"
                            >
                                <img
                                    src={instagram}
                                    alt="Instagram"
                                    style={{ width: '1em', height: '1em' }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="content has-text-centered">
            <CopyrightInfo>
                © Follow Tuscany {new Date().getFullYear()}
            </CopyrightInfo>
        </div>
    </StyledFooter>
);

export default Footer;
