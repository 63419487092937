import React, { useState } from 'react';
import { Link } from 'gatsby';

import logo from '../img/follow-tuscany-logo.svg';

const NavBar = () => {
    const [active, setActive] = useState(false);
    return (
        <nav
            className="navbar is-transparent"
            role="navigation"
            aria-label="main-navigation"
        >
            <div className="container">
                <div className="navbar-brand">
                    <Link to="/" title="Logo">
                        <img
                            src={logo}
                            alt="Follow Tuscany"
                            style={{ width: '220px', height: '100%' }}
                        />
                    </Link>
                    <div
                        className={`navbar-burger burger ${
                            active ? 'is-active' : ''
                        }`}
                        data-target="navMenu"
                        onClick={() => setActive(!active)}
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
                <div
                    id="navMenu"
                    className={`navbar-menu ${active ? 'is-active' : ''}`}
                >
                    <div className="navbar-start has-text-centered">
                        <Link className="navbar-item" to="/about">
                            About Us
                        </Link>
                        <Link className="navbar-item" to="/your-home-away">
                            Your Home Away
                        </Link>
                        <Link className="navbar-item" to="/courses">
                            Our Courses
                        </Link>
                        <Link className="navbar-item" to="/slow-living">
                            Slow Living
                        </Link>
                        <Link className="navbar-item" to="/contact-us">
                            Contact
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
